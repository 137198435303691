import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';


export const useAccountStore = defineStore({
	id: 'account',
	state: () => ({
		selectedId: useStorage('selectedAccountId', ''),
		selectedLogin: useStorage('selectedAccountLogin', '')
	}),
	actions: {
		setSelected(id: any, login: any) {
			this.selectedId = id;
			this.selectedLogin = login;
		}
	}
})