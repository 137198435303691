export function waitForElement(selector: string) {
	return new Promise(resolve => {
		if (document.querySelector(selector)) {
			return resolve(document.querySelector(selector));
		}

		const observer = new MutationObserver(mutations => {
			if (document.querySelector(selector)) {
				resolve(document.querySelector(selector));
				observer.disconnect();
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true
		});
	});
}

export function getMonthRuNameDativeCaseByNominativeCase(value: string): string {
	switch (value.toLowerCase()) {
		case 'январь':
			return 'январю';
		case 'февраль':
			return 'февралю';
		case 'март':
			return 'марту';
		case 'апрель':
			return 'апрелю';
		case 'май':
			return 'маю';
		case 'июнь':
			return 'июню';
		case 'июль':
			return 'июлю';
		case 'август':
			return 'августу';
		case 'сентябрь':
			return 'сентябрю';
		case 'октябрь':
			return 'октябрю';
		case 'ноябрь':
			return 'ноябрю';
		case 'декабрь':
			return 'декабрю';
		default:
			return value;
	}
}
