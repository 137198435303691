
	import { Chart, ChartConfiguration } from 'chart.js/auto';
	import { waitForElement } from '../../utils/functions';

	export default {
		props: {
			lables: Object,
			values: Object,
			chartId: String,
			tooltipDimension: String
		},
		setup(props: any) {
			async function createPlot() {
				const chartData: ChartConfiguration = {
					type: 'doughnut',
					data: {
						labels: props.lables,
						datasets: [{
							label: props.tooltipDimension,
							data: props.values,
							hoverOffset: 6
						}]
					}
				};

				const element = await waitForElement('#' + props.chartId) as HTMLCanvasElement;
				new Chart(element, chartData);
			}

			(async () => {
				await createPlot();
			})();
		}
	}
