
	import { ref, Ref, computed } from 'vue';
	import axios from 'axios';
	import moment from 'moment';
	import LoadingIndicatorVueComponent from '../shared/loading-indicator.vue';
	import MyshowsUserProfileLinkVueComponent from '../shared/myshows-user-profile-link.vue';
	import { useAccountStore } from '../../storage';
	import DateUtils from '../../utils/date';

	export default {
		components: {
			'loading-indicator': LoadingIndicatorVueComponent,
			'myshows-user-profile-link': MyshowsUserProfileLinkVueComponent
		},
		setup() {
			const loadinOAuth2AuthorizationUrl = ref(true);
			const oAuth2AuthorizationUrl = ref('');
			const authorizationCode = ref('');
			const ensuringAccount = ref(false);
			const loadingAccounts = ref(true);
			const ensuringResult: Ref<any> = ref(null);
			const accounts = ref(null);

			const isInputValid = computed(() => !!authorizationCode.value && authorizationCode.value.length > 0);
			const isEnsuringStateError = computed(() => ensuringResult.value.state == "Error");
			const isEnsuringStateCreated = computed(() => ensuringResult.value.state == "Created");
			const isEnsuringStateUpdated = computed(() => ensuringResult.value.state == "Updated");

			function loadOAuth2AuthorizationUrl() {
				loadinOAuth2AuthorizationUrl.value = true;
				axios.get("/api/accounts/authorization-url")
					.then(res => {
						oAuth2AuthorizationUrl.value = res.data;
						loadinOAuth2AuthorizationUrl.value = false;
					});
			}

			function laodAccounts() {
				loadingAccounts.value = true;
				axios.get("/api/accounts")
					.then(res => {
						accounts.value = res.data;
						loadingAccounts.value = false;
					});
			}

			function ensureAccount() {
				ensuringAccount.value = true;
				axios.post("/api/accounts/ensure", {
					authorizationCode: authorizationCode.value
				})
				.then(res => {
					ensuringResult.value = res.data;
					ensuringAccount.value = false;
					if (!isEnsuringStateError.value) {
						authorizationCode.value = '';
						laodAccounts();
					}
				});
			}

			function formatDate(value: any): string {
				return DateUtils.toDateAsString(value);
			}

			function getTimeIntervalInDays(dateAsString: string): string {
				return DateUtils.durationAsString(
					moment.utc(dateAsString),
					moment.utc()
				);
			}

			const accountStore = useAccountStore();

			loadOAuth2AuthorizationUrl();
			laodAccounts();

			return {
				loadinOAuth2AuthorizationUrl,
				oAuth2AuthorizationUrl,
				authorizationCode,
				ensureAccount,
				isInputValid,
				ensuringAccount,
				isEnsuringStateError,
				isEnsuringStateCreated,
				isEnsuringStateUpdated,
				loadingAccounts,
				ensuringResult,
				formatDate,
				getTimeIntervalInDays,
				accounts,
				accountStore
			}
		}
	}
