
	import NavBarVueComponent from './components/layout/nav-bar.vue';
	import FooterVueComponent from './components/layout/footer.vue';

	export default {
		name: 'App',
		components: {
			'nav-bar': NavBarVueComponent,
			'footer-component': FooterVueComponent
		}
	}
