
	import ScrollableContainerVueComponent from './scrollable-container.vue';

	export default {
		components: {
			'scrollable-container': ScrollableContainerVueComponent
		},
		props: {
			title: String,
			subtitle: String,
			minWidth: {
				type: Number,
				default: null,
				required: false
			}
		},
		setup(props: any) {
			return {
				showTitle: !!props.title,
				showSubtitle: !!props.subtitle
			}
		}
	}
