
	import { watch } from 'vue';
	import Chart from 'chart.js/auto';
	import { waitForElement } from '../../utils/functions';
	import { monthRuNames } from '../../utils/constants';
	import Item from './monthly-comparative-chart-item';

	export default {
		props: {
			data: Object,
			chartId: String,
			rerenderIndicator: Boolean,
			tooltipDimension: String,
			yTitle: String
		},
		setup(props: any) {
			let chart: any = null;

			watch(() => props.rerenderIndicator,
				async () => {
					if (chart) {
						chart.destroy();
						await createPlot();
					}
				}
			);

			async function createPlot() {
				const chartData = {
					type: 'bar',
					data: {
						labels: monthRuNames,
						datasets: [] as any[]
					},
					options: {
						scales: {
							y: {
								beginAtZero: true,
								title: {
									display: true,
									text: props.yTitle,
									font: function (context: any) {
										const height = context.chart.height;
										const size = Math.round(height / 30);
										return {
											size: size
										};
									}
								}
							}
						},
						plugins: {
							tooltip: {
								callbacks: {
									label: function (context: any) {
										return context.dataset.label + ': ' + context.formattedValue + ' ' + props.tooltipDimension;
									}
								}
							}
						}
					}
				};

				props.data.forEach(function (element: Item) {
					chartData.data.datasets.push({
						label: `${element.userLogin} (${element.year} г.)`,
						data: element.valueByMonths
					});
				});

				const element = await waitForElement('#' + props.chartId) as HTMLCanvasElement;
				chart = new Chart(element, chartData);
			}

			(async () => {
				await createPlot();
			})();
		}
	}
