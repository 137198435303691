import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5410e7ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fst-italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: _normalizeClass(['badge', $props.backgroundClass, 'hint-span'])
    }, "  ", 2),
    _createElementVNode("span", _hoisted_1, _toDisplayString($props.text), 1)
  ], 64))
}