import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "link-dark",
    href: 'https://myshows.me/' + $props.login,
    target: "_blank"
  }, _toDisplayString($props.login), 9, _hoisted_1))
}