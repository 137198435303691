import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36a9b6ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table table-borderless p-0 m-0" }
const _hoisted_3 = { class: "px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item) => {
              return (_openBlock(), _createElementBlock("span", {
                key: item.title,
                class: _normalizeClass(["badge with-margin-x", item.highlighted ? 'bg-success' : 'bg-primary'])
              }, [
                _createVNode(_component_router_link, {
                  class: "link-light text-decoration-none",
                  to: { name: $setup.profileShowRouteName, params: { id: item.id }}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ], 2))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}