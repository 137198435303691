
	import MyshowsUserProfileLinkVueComponent from '../shared/myshows-user-profile-link.vue';

	export default {
		components: {
			'myshows-user-profile-link': MyshowsUserProfileLinkVueComponent
		},
		setup() {
			return {
				currentYear: new Date().getFullYear()
			}
		}
	}
