import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ff45c14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fw-bold mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_heatmap = _resolveComponent("calendar-heatmap")!
  const _component_scrollable_container = _resolveComponent("scrollable-container")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.yearsData, (yearData) => {
    return (_openBlock(), _createElementBlock(_Fragment, {
      key: yearData.year
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(yearData.year), 1),
      _createVNode(_component_scrollable_container, { minWidth: 700 }, {
        default: _withCtx(() => [
          _createVNode(_component_calendar_heatmap, {
            values: $setup.getValues(yearData.dates),
            "end-date": $setup.getEndDate(yearData.year),
            round: 1,
            max: 5,
            "range-color": ['#f0f0f0','#c3facf', '#6f9', '#0c3', '#093', '#063'],
            "no-data-text": "No episodes watched",
            "tooltip-unit": "episodes"
          }, null, 8, ["values", "end-date"])
        ]),
        _: 2
      }, 1024)
    ], 64))
  }), 128))
}