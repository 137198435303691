
	import { ref } from 'vue';
	import axios from 'axios';
	import { routNames } from '../../router';
	import { useAccountStore } from '../../storage';
	import LoadingIndicatorVueComponent from '../shared/loading-indicator.vue';

	export default {
		components: {
			'loading-indicator': LoadingIndicatorVueComponent,
		},
		setup() {
			const loading = ref(true);
			const entities = ref(null);

			const buildRaiting = (value: number) => value == 0 ? '–' : value;

			function getWatchingStatus(value: string): string {
				switch (value) {
					case 'Watching':
						return 'Смотрю';
					case 'Later':
						return 'Буду смотреть';
					case 'Cancelled':
						return 'Перестал смотреть';
					case 'Finished':
						return 'Полностью посмотрел';
					default:
						return value;
				}
			}

			function getWatchedEpisodePercentage(watchedCount: number, totalCount: number): number {
				return Math.round(watchedCount / totalCount * 100);
			}

			function isFullyWatched(watchedCount: number, totalCount: number): boolean {
				return getWatchedEpisodePercentage(watchedCount, totalCount) == 100;
			}

			function showTitleOriginal(item: any): boolean {
				return !!item.titleOriginal && item.title != item.titleOriginal;
			}

			const profileShowRouteName = routNames.ProfileShow;

			const accountStore = useAccountStore();
			const accountId = parseInt(accountStore.selectedId) ? accountStore.selectedId : 0;

			axios.get(`/api/profile-shows?accountId=${accountId}`)
				.then(res => {
					entities.value = res.data;
					loading.value = false;
				});

			return {
				loading,
				entities,
				buildRaiting,
				getWatchingStatus,
				getWatchedEpisodePercentage,
				showTitleOriginal,
				isFullyWatched,
				profileShowRouteName
			}
		}
	}
