
	import moment from 'moment';
	import { CalendarHeatmap } from 'vue3-calendar-heatmap';
	import ScrollableContainerVueComponent from './scrollable-container.vue';

	export default {
		components: {
			'calendar-heatmap': CalendarHeatmap,
			'scrollable-container': ScrollableContainerVueComponent
		},
		props: {
			yearsData: Object
		},
		setup() {
			function getValues(daysData: any) {
				return daysData.map((x: any) => ({
					date: moment(x.date),
					count: x.watchedEpisodeCount
				}));
			}

			function getEndDate(year: number): moment.Moment {
				return moment([year]).endOf('year');
			}

			return {
				getValues,
				getEndDate
			}
		}
	}
