import moment from 'moment';

export default class DateUtils {
	public static toDateAsString(value: any): string {
		return value ? moment(value).format('DD.MM.YYYY') : '';
	}

	public static durationAsString(start: moment.Moment, end: moment.Moment): string {
		const reversed: boolean = start > end;
		if (reversed) {
			[start, end] = [end, start]
		}

		const duration = moment.duration(end.diff(start));

		const days = Math.floor(duration.asDays());
		const daysFormatted = days ? `${days} д ` : '';

		const hours = duration.hours();
		const hoursFormatted = `${hours} ч `;

		const minutes = duration.minutes();
		const minutesFormatted = `${minutes} м`;

		let result = [daysFormatted, hoursFormatted, minutesFormatted].join('');

		if (reversed) {
			result = 'через ' + result;
		} else {
			result += ' назад'
		}

		return result;
	}
}