
	import ShowsListVueComponent from '../shared/shows-list.vue';
	import ShowListItem from '../shared/shows-list-item';

	export default {
		components: {
			'show-list': ShowsListVueComponent
		},
		props: {
			entities: Array
		},
		setup() {
			function buildItems(entity: any): ShowListItem[] {
				return entity.map((x: any) => ({
					id: x.id,
					title: x.title,
					highlighted: false
				} as ShowListItem));
			}

			function getItemCount(items: any): number {
				return items.length;
			}

			return {
				getItemCount,
				buildItems
			}
		}
	}
