
	export default {
		props: {
			minWidth: {
				type: Number,
				default: null,
				required: false
			}
		}
	}
