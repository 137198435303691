import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd9d27c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card without-border" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  key: 0,
  class: "card-title"
}
const _hoisted_4 = {
  key: 1,
  class: "card-subtitle mb-2 text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scrollable_container = _resolveComponent("scrollable-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.showTitle)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString($props.title), 1))
        : _createCommentVNode("", true),
      ($setup.showSubtitle)
        ? (_openBlock(), _createElementBlock("h6", _hoisted_4, _toDisplayString($props.subtitle), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_scrollable_container, { minWidth: $props.minWidth }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["minWidth"])
    ])
  ]))
}