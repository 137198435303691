
	import Chart from 'chart.js/auto';
	import { waitForElement } from '../../utils/functions';

	export default {
		props: {
			data: Object,
			chartId: String,
			tooltipDimension: String,
			xTitle: String,
			yTitle: String
		},
		setup(props: any) {
			async function createPlot() {
				const chartData = {
					type: 'bar',
					data: {
						labels: ['Без рейтинга', '1', '2', '3', '4', '5'],
						datasets: [{
							data: [
								props.data.without,
								props.data.one,
								props.data.two,
								props.data.three,
								props.data.four,
								props.data.five
							]
						} as any]
					},
					options: {
						scales: {
							x: {
								title: {
									display: true,
									text: props.xTitle,
									font: function (context: any) {
										const height = context.chart.height;
										const size = Math.round(height / 30);
										return {
											size: size
										};
									}
								}
							},
							y: {
								beginAtZero: true,
								title: {
									display: true,
									text: props.yTitle,
									font: function (context: any) {
										const height = context.chart.height;
										const size = Math.round(height / 30);
										return {
											size: size
										};
									}
								}
							}
						},
						plugins: {
							legend: {
								display: false
							},
							tooltip: {
								callbacks: {
									title: function (context: any) {
										switch (context[0].label) {
											case 'Без рейтинга':
												return context[0].label;
											default:
												return 'Оценка «' + context[0].label + '»';
										}
									},
									label: function (context: any) {
										return context.formattedValue + ' ' + props.tooltipDimension;
									}
								}
							}
						}
					}
				};

				const element = await waitForElement('#' + props.chartId) as HTMLCanvasElement;
				new Chart(element, chartData);
			}

			(async () => {
				await createPlot();
			})();
		}
	}
