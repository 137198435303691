
	import { watch, ref, Ref } from 'vue';
	import axios from 'axios';
	import moment from 'moment';
	import DateUtils from '../../utils/date';
	import LoadingIndicatorVueComponent from '../shared/loading-indicator.vue';
	import MyshowsUserProfileLinkVueComponent from '../shared/myshows-user-profile-link.vue';
	import CardVueComponent from '../shared/card.vue';
	import MonthlyCumulativeComparativeChartVueComponent from './monthly-cumulative-comparative-chart.vue';
	import MonthlyRegularComparativeChartVueComponent from './monthly-regular-comparative-chart.vue';
	import MonthlyComparativeChartItem from './monthly-comparative-chart-item';

	interface FormItem {
		accountId: number;
		userLogin: string;
		synchronizationDateTime: string;
		years: number[];
		enabled: boolean;
		selectedYear: number;
	}

	export default {
		components: {
			'loading-indicator': LoadingIndicatorVueComponent,
			'myshows-user-profile-link': MyshowsUserProfileLinkVueComponent,
			'card': CardVueComponent,
			'monthly-cumulative-comparative-chart': MonthlyCumulativeComparativeChartVueComponent,
			'monthly-regular-comparative-chart': MonthlyRegularComparativeChartVueComponent
		},
		setup() {
			const loading = ref(true);
			let originalData: any = null;
			const formItems: Ref<FormItem[]> = ref([]);
			const monthlyEpisodesCumulativeComparativeChartItems: Ref<MonthlyComparativeChartItem[]> = ref([]);
			const monthlyWastedTimeCumulativeComparativeChartItems: Ref<MonthlyComparativeChartItem[]> = ref([]);
			const monthlyEpisodesRegularComparativeChartItems: Ref<MonthlyComparativeChartItem[]> = ref([]);
			const monthlyWastedTimeRegularComparativeChartItems: Ref<MonthlyComparativeChartItem[]> = ref([]);

			const changed: Ref<boolean> = ref(false);

			function calculateMonthlyComparativeChartItems(): void {
				monthlyEpisodesCumulativeComparativeChartItems.value = [];
				monthlyWastedTimeCumulativeComparativeChartItems.value = [];
				monthlyEpisodesRegularComparativeChartItems.value = [];
				monthlyWastedTimeRegularComparativeChartItems.value = [];
				formItems.value.forEach(x => {
					if (x.enabled) {
						const userOriginalData = originalData.items.find((y: any) => y.userLogin == x.userLogin);
						monthlyEpisodesCumulativeComparativeChartItems.value.push({
							accountId: x.accountId,
							userLogin: x.userLogin,
							year: x.selectedYear,
							valueByMonths: userOriginalData
								.cumulativeEpisodeCount
								.find((y: any) => y.year == x.selectedYear)
								.valueToMonthNumber
						});
						monthlyWastedTimeCumulativeComparativeChartItems.value.push({
							accountId: x.accountId,
							userLogin: x.userLogin,
							year: x.selectedYear,
							valueByMonths: userOriginalData
								.cumulativeWastedTimeInHours
								.find((y: any) => y.year == x.selectedYear)
								.valueToMonthNumber
						});
						monthlyEpisodesRegularComparativeChartItems.value.push({
							accountId: x.accountId,
							userLogin: x.userLogin,
							year: x.selectedYear,
							valueByMonths: userOriginalData
								.monthlyEpisodeCount
								.find((y: any) => y.year == x.selectedYear)
								.valueToMonthNumber
						});
						monthlyWastedTimeRegularComparativeChartItems.value.push({
							accountId: x.accountId,
							userLogin: x.userLogin,
							year: x.selectedYear,
							valueByMonths: userOriginalData
								.monthlyWastedTimeInHours
								.find((y: any) => y.year == x.selectedYear)
								.valueToMonthNumber
						});
					}
				});
			}

			watch(() => formItems,
				() => {
					calculateMonthlyComparativeChartItems();
					changed.value = !changed.value;
				},
				{ deep: true }
			);

			axios
				.get('api/comparative-user-statistics')
				.then((res: any) => {
					originalData = res.data;
					originalData.items.forEach((x: any) => {
						const years = x.cumulativeEpisodeCount.map((y: any) => y.year);
						formItems.value.push({
							accountId: x.accountId,
							userLogin: x.userLogin,
							synchronizationDateTime: DateUtils.durationAsString(
								moment.utc(x.synchronizationDateTime),
								moment.utc()
							),
							years: years,
							enabled: true,
							selectedYear: years.slice(-1)[0]
						});
					});
					loading.value = false;
				});

			return {
				loading,
				formItems,
				monthlyEpisodesCumulativeComparativeChartItems,
				monthlyWastedTimeCumulativeComparativeChartItems,
				monthlyEpisodesRegularComparativeChartItems,
				monthlyWastedTimeRegularComparativeChartItems,
				changed
			}
		}
	}
