import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72cedf06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-0" }
const _hoisted_2 = { class: "mb-0 fw-bold" }
const _hoisted_3 = { class: "text-secondary" }
const _hoisted_4 = { class: "text-secondary fst-italic total-info m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hint_lable = _resolveComponent("hint-lable")!
  const _component_show_list = _resolveComponent("show-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, [
      _createVNode(_component_hint_lable, {
        text: "сериалы, начатые в текущем году",
        backgroundClass: "bg-success"
      })
    ]),
    _createElementVNode("p", null, [
      _createVNode(_component_hint_lable, {
        text: "сериалы, начатые в прошлых годах",
        backgroundClass: "bg-primary"
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.entities, (entity) => {
      return (_openBlock(), _createElementBlock("div", {
        key: entity.year,
        class: "mb-3"
      }, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(entity.year) + " ", 1),
          _createElementVNode("span", _hoisted_3, "(" + _toDisplayString($setup.getTotalShowCount(entity.showInformations)) + ")", 1)
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString($setup.getTotalWastedTimeInHours(entity.showInformations)) + " часов / " + _toDisplayString($setup.getTotalWatchedEpisodeCount(entity.showInformations)) + " эпизодов ", 1),
        _createVNode(_component_show_list, {
          items: $setup.buildItems(entity.showInformations)
        }, null, 8, ["items"])
      ]))
    }), 128))
  ], 64))
}