
	import ShowsListVueComponent from '../shared/shows-list.vue';
	import ShowListItem from '../shared/shows-list-item';
	import YearShowHintLableVueComponent from './year-show-hint-lable.vue';

	export default {
		components: {
			'hint-lable': YearShowHintLableVueComponent,
			'show-list': ShowsListVueComponent
		},
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		setup(props: any) {
			function buildItems(entity: any): ShowListItem[] {
				return entity.map((x: any) => ({
					id: x.id,
					title: `${x.title} (${x.wastedTimeInHours.toFixed(1)} / ${x.watchedEpisodeCount})`,
					highlighted: x.hasNotWatchedBefore
				} as ShowListItem));
			}

			function getTotalShowCount(items: any): number {
				return items.length;
			}

			function getTotalWastedTimeInHours(items: any): number {
				return items.map((x: any) => x.wastedTimeInHours).reduce((prev: number, next: number) => prev + next).toFixed(1);
			}

			function getTotalWatchedEpisodeCount(items: any): number {
				return items.map((x: any) => x.watchedEpisodeCount).reduce((prev: number, next: number) => prev + next);
			}

			return {
				buildItems,
				entities: props.data,
				getTotalShowCount,
				getTotalWastedTimeInHours,
				getTotalWatchedEpisodeCount
			}
		}
	}
