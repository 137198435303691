
	import { computed } from 'vue';

	export default {
		props: {
			text: String
		},
		setup(props: any) {
			const showText = computed(() => !!props.text);

			return {
				showText
			}
		}
	}
