import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-subtitle mb-2 text-muted" }
const _hoisted_5 = { class: "progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", _hoisted_3, _toDisplayString($props.title), 1),
      _createElementVNode("h6", _hoisted_4, _toDisplayString($props.progress.watched) + " / " + _toDisplayString($props.progress.remaining), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "progress-bar",
          role: "progressbar",
          style: _normalizeStyle({ width: $setup.watchedPercentage + '%' })
        }, null, 4)
      ])
    ])
  ]))
}