import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _createVNode(_component_nav_bar)
    ]),
    _createElementVNode("main", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ])
    ]),
    _createElementVNode("footer", _hoisted_2, [
      _createVNode(_component_footer_component)
    ])
  ], 64))
}