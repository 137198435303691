
	import { routNames } from '../../router';

	export default {
		props: {
			items: Array
		},
		setup() {
			return {
				profileShowRouteName: routNames.ProfileShow
			}
		}
	}
