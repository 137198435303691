import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "fw-bold p-0 mb-0" }
const _hoisted_2 = { class: "text-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_show_list = _resolveComponent("show-list")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.entities, (entity) => {
    return (_openBlock(), _createElementBlock("div", {
      key: entity.title,
      class: "p-0 mb-2"
    }, [
      _createElementVNode("p", _hoisted_1, [
        _createTextVNode(_toDisplayString(entity.title) + " ", 1),
        _createElementVNode("span", _hoisted_2, "(" + _toDisplayString($setup.getItemCount(entity.shows)) + ")", 1)
      ]),
      _createVNode(_component_show_list, {
        items: $setup.buildItems(entity.shows)
      }, null, 8, ["items"])
    ]))
  }), 128))
}