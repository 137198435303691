
	import { ref, Ref, computed } from 'vue';
	import axios from 'axios';
	import { useAccountStore } from '../../storage';
	import DateUtils from '../../utils/date';
	import LoadingIndicatorVueComponent from '../shared/loading-indicator.vue';
	import CardVueComponent from '../shared/card.vue';
	import EpisodesPerDayHeatmapVueComponent from '../shared/episodes-per-day-heatmap.vue';
	import RatingChartVueComponent from '../shared/rating-chart.vue';

	export default {
		components: {
			'loading-indicator': LoadingIndicatorVueComponent,
			'card': CardVueComponent,
			'episodes-per-day-heatmap': EpisodesPerDayHeatmapVueComponent,
			'rating-chart': RatingChartVueComponent
		},
		props: {
			id: {
				type: String,
				required: true
			}
		},
		setup(props: any) {
			const loading = ref(true);
			const data: Ref<any> = ref(null);

			const accountStore = useAccountStore();
			const accountId = parseInt(accountStore.selectedId) ? accountStore.selectedId : 0;

			function showTitleOriginal(item: any): boolean {
				return !!item.titleOriginal && item.title != item.titleOriginal;
			}

			axios
				.get(`/api/profile-shows/${props.id}?accountId=${accountId}`)
				.then(res => {
					data.value = res.data;
					loading.value = false;
				});

			const hasWatchedEpisodes = computed(() => data.value.years.length > 0);

			function formatDate(value: any): string {
				return DateUtils.toDateAsString(value);
			}

			return {
				loading,
				hasWatchedEpisodes,
				showTitleOriginal,
				data,
				formatDate
			}
		}
	}
