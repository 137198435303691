
	import Chart from 'chart.js/auto';
	import { waitForElement } from '../../utils/functions';
	import { monthRuNames } from '../../utils/constants';

	export default {
		props: {
			data: Object,
			chartId: String,
			tooltipDimension: String,
			yTitle: String
		},
		setup(props: any) {
			async function createPlot() {
				const chartData = {
					type: 'bar',
					data: {
						labels: monthRuNames,
						datasets: [] as any[]
					},
					options: {
						scales: {
							y: {
								beginAtZero: true,
								title: {
									display: true,
									text: props.yTitle,
									font: function (context: any) {
										const height = context.chart.height;
										const size = Math.round(height / 30);
										return {
											size: size
										};
									}
								}
							}
						},
						plugins: {
							tooltip: {
								callbacks: {
									label: function (context: any) {
										return context.dataset.label + ' года: ' + context.formattedValue + ' ' + props.tooltipDimension;
									}
								}
							}
						}
					}
				};

				const currentDate = new Date();
				const currentYear = currentDate.getFullYear();
				const currentMonth = currentDate.getMonth();

				props.data.forEach(function (element: any, index: number) {
					if (element.year == currentYear) {
						element.valueToMonthNumber = element.valueToMonthNumber.slice(0, currentMonth + 1);
					}

					chartData.data.datasets.push({
						label: element.year,
						data: element.valueToMonthNumber,
						hidden: (index != props.data.length - 1) && (props.data.length > 0)
					});
				});

				const element = await waitForElement('#' + props.chartId) as HTMLCanvasElement;
				new Chart(element, chartData);
			}

			(async () => {
				await createPlot();
			})();
		}
	}
