import { createRouter, createWebHistory } from 'vue-router';
import SummaryStatisticsVueComponent from './components/summary-statistics/summary-statistics.vue';
import UserProfileVueComponent from './components/profile/profile.vue';
import ProfileShowsVueComponent from './components/profile-shows/profile-shows.vue';
import ProfileShowVueComponent from './components/profile-shows/profile-show.vue';
import RaceVueComponent from './components/race/race.vue';
import AccountsVueComponent from './components/accounts/accounts.vue';


export const routNames = {
	Index: 'Index',
	Profile: 'Profile',
	SummaryStatistics: 'SummaryStatistics',
	ProfileShows: 'ProfileShows',
	ProfileShow: 'ProfileShow',
	Race: 'Race',
	Accounts: 'Accounts'
}

export default createRouter({
	history: createWebHistory(),
	routes: [
		{
			name: routNames.Index,
			path: '/',
			redirect: routNames.Profile
		},
		{
			name: routNames.Profile,
			path: '/profile',
			component: UserProfileVueComponent
		},
		{
			name: routNames.SummaryStatistics,
			path: '/summary-statistics',
			component: SummaryStatisticsVueComponent
		},
		{
			name: routNames.ProfileShows,
			path: '/shows',
			component: ProfileShowsVueComponent
		},
		{
			name: routNames.ProfileShow,
			path: '/shows/:id',
			component: ProfileShowVueComponent,
			props: true
		},
		{
			name: routNames.Race,
			path: '/race',
			component: RaceVueComponent
		},
		{
			name: routNames.Accounts,
			path: '/accounts',
			component: AccountsVueComponent
		}
	]
})