
	import { ref, computed } from 'vue';
	import { v4 as uuidv4 } from 'uuid';
	import axios from 'axios';
	import moment from 'moment';
	import { useAccountStore } from '../../storage';
	import DateUtils from '../../utils/date';
	import LoadingIndicatorVueComponent from '../shared/loading-indicator.vue';
	import EpisodesPerDayHeatmapVueComponent from '../shared/episodes-per-day-heatmap.vue';
	import MyshowsUserProfileLinkVueComponent from '../shared/myshows-user-profile-link.vue';
	import CardVueComponent from '../shared/card.vue';
	import RatingChartVueComponent from '../shared/rating-chart.vue';
	import MounthlyRegularSummaryChartVueComponent from './mounthly-regular-summary-chart.vue';
	import MonthlyCumulativeSummaryChartVueComponent from './monthly-cumulative-summary-chart.vue';
	import DoughnutChartVueComponent from './doughnut-chart.vue';
	import ShowGenresVueComponent from './show-genres.vue';
	import YearShowsVueComponent from './year-shows.vue';

	const State = {
		LoadingFromCache: 'LoadingFromCache',
		CacheIsEmpty: 'CacheIsEmpty',
		LoadingFromApi: 'LoadingFromApi',
		Loaded: 'Loaded'
	};

	export default {
		components: {
			'loading-indicator': LoadingIndicatorVueComponent,
			'card': CardVueComponent,
			'myshows-user-profile-link': MyshowsUserProfileLinkVueComponent,
			'mounthly-regular-summary-chart': MounthlyRegularSummaryChartVueComponent,
			'monthly-cumulative-summary-chart': MonthlyCumulativeSummaryChartVueComponent,
			'rating-chart': RatingChartVueComponent,
			'episodes-per-day-heatmap': EpisodesPerDayHeatmapVueComponent,
			'doughnut-chart': DoughnutChartVueComponent,
			'show-genres': ShowGenresVueComponent,
			'year-shows': YearShowsVueComponent
		},
		setup() {
			const data = ref<any>(null);
			const progressPercentage = ref(0);
			const state = ref(State.LoadingFromCache);

			const isStateLoadingFromCache = computed(() => state.value == State.LoadingFromCache);
			const isStateCacheIsEmpty = computed(() => state.value == State.CacheIsEmpty);
			const isStateLoadingFromApi = computed(() => state.value == State.LoadingFromApi);
			const isStateLoaded = computed(() => state.value == State.Loaded);

			const accountStore = useAccountStore();
			const accountId = parseInt(accountStore.selectedId) ? accountStore.selectedId : 0;

			const genresDistribution = ref<any>({
				titles: [],
				showCounts: []
			});

			function checkSynchronizationProgress(referenceGuid: string) {
				let canCheck = true;
				let interval = setInterval(function () {
					if (canCheck) {
						canCheck = false;
						axios
							.get(`/api/summary-statistics/check-synchronization-progress?referenceGuid=${referenceGuid}`)
							.then(res => {
								const data = res.data;
								if (data) {
									progressPercentage.value = Math.round(data.currentStepNumber / data.totalStepCount * 100);
									if (data.isCompleted) {
										clearInterval(interval);
									}
								}
							})
							.finally(() => {
								canCheck = true;
							});
					}
				}, 2000);
			}

			function synchronize(referenceGuid: string) {
				state.value = State.LoadingFromApi;
				axios
					.get(`/api/summary-statistics/synchronize?accountId=${accountId}&referenceGuid=${referenceGuid}`)
					.then(() => loadFromCache());
			}

			function loadFromApi() {
				progressPercentage.value = 0;
				const referenceGuid = uuidv4();
				synchronize(referenceGuid);
				checkSynchronizationProgress(referenceGuid);
			}

			const synchronizedAgo = ref('');

			function loadFromCache() {
				state.value = State.LoadingFromCache;
				axios
					.get(`/api/summary-statistics/load-from-cache?accountId=${accountId}`)
					.then(res => {
						if (!res.data) {
							state.value = State.CacheIsEmpty;
						} else {
							data.value = res.data;

							synchronizedAgo.value = DateUtils.durationAsString(
								moment.utc(data.value.lastUpdatedUtcDateTime),
								moment.utc()
							);

							genresDistribution.value.titles = data.value.genres.map((x: any) => x.title);
							genresDistribution.value.showCounts = data.value.genres.map((x: any) => x.shows.length);

							state.value = State.Loaded;
						}
					});
			}

			loadFromCache();

			return {
				data,
				genresDistribution,
				progressPercentage,
				loadFromApi,
				isStateLoadingFromCache,
				isStateCacheIsEmpty,
				isStateLoadingFromApi,
				isStateLoaded,
				synchronizedAgo,
				userLogin: accountStore.selectedLogin
			}
		}
	}
