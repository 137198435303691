
	import { computed } from 'vue';

	export default {
		props: {
			title: String,
			progress: Object
		},
		setup(props: any) {
			const watchedPercentage = computed(() => Math.round(props.progress.watched / props.progress.total * 100));

			return {
				watchedPercentage
			}
		}
	}
