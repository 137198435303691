import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-responsive"
}
const _hoisted_2 = { class: "p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.minWidth)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", {
          class: "table table-borderless p-0 m-0",
          style: _normalizeStyle({ 'min-width': $props.minWidth + 'px' })
        }, [
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ])
        ], 4)
      ]))
    : _renderSlot(_ctx.$slots, "default", { key: 1 })
}