
	import { ref, Ref, computed } from 'vue';
	import moment from 'moment';
	import axios from 'axios';
	import { useAccountStore } from '../../storage';
	import LoadingIndicatorVueComponent from '../shared/loading-indicator.vue';
	import MyshowsUserProfileLinkVueComponent from '../shared/myshows-user-profile-link.vue';
	import WatchingProgressVueComponent from './profile-watching-progress.vue';

	export default {
		components: {
			'loading-indicator': LoadingIndicatorVueComponent,
			'myshows-user-profile-link': MyshowsUserProfileLinkVueComponent,
			'watching-progress': WatchingProgressVueComponent
		},
		setup() {
			const loading = ref(true);
			const userProfile: Ref<any> = ref(null);

			const proDaysLeft = computed((): number | null => {
				if (userProfile.value) {
					const endDate = moment(userProfile.value.proInformation.expirationDate, "DD.MM.YYYY");
					const todaysDate = moment();
					return endDate.diff(todaysDate, 'days');
				}
				return null;
			});

			const accountStore = useAccountStore();
			const accountId = parseInt(accountStore.selectedId) ? accountStore.selectedId : 0;

			axios
				.get(`/api/user-profile?accountId=${accountId}`)
				.then(res => {
					userProfile.value = res.data;
					loading.value = false;
				});

			return {
				loading,
				userProfile,
				proDaysLeft
			}
		}
	}
