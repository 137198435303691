
	import { computed } from 'vue';
	import { routNames } from '../../router';
	import { useAccountStore } from '../../storage';
	import GearIconVueComponent from './gear-icon.vue';

	export default {
		components: {
			'gear-icon': GearIconVueComponent
		},
		setup() {
			const accountStore = useAccountStore();

			const settingsNavItemText = computed(() =>
				accountStore.selectedLogin && accountStore.selectedLogin.length > 0 ? accountStore.selectedLogin : 'Настройки');

			return {
				routNames: routNames,
				settingsNavItemText
			}
		}
	}
