
	import { watch } from 'vue';
	import Chart from 'chart.js/auto';
	import { waitForElement, getMonthRuNameDativeCaseByNominativeCase } from '../../utils/functions';
	import { monthRuNames } from '../../utils/constants';
	import Item from './monthly-comparative-chart-item';

	export default {
		props: {
			chartId: String,
			data: Object,
			rerenderIndicator: Boolean,
			yTitle: String,
			tooltipDimension: String
		},
		setup(props: any) {
			let chart: any = null;

			watch(() => props.rerenderIndicator,
				async () => {
					if (chart) {
						chart.destroy();
						await createPlot();
					}
				}
			);

			async function createPlot() {
				const chartData = {
					type: 'line',
					data: {
						labels: monthRuNames,
						datasets: [] as any[]
					},
					options: {
						responsive: true,
						maintainAspectRatio: true,
						scales: {
							y: {
								beginAtZero: true,
								title: {
									display: true,
									text: props.yTitle,
									font: function (context: any) {
										const height = context.chart.height;
										const size = Math.round(height / 30);
										return {
											size: size
										};
									}
								}
							}
						},
						plugins: {
							tooltip: {
								callbacks: {
									title: function (context: any) {
										return "Просмотрено к " + getMonthRuNameDativeCaseByNominativeCase(context[0].label);
									},
									label: function (context: any) {
										return context.dataset.label + ': ' + context.formattedValue + ' ' + props.tooltipDimension;
									}
								}
							}
						}
					}
				};

				const currentDate = new Date();
				const currentYear = currentDate.getFullYear();
				const currentMonth = currentDate.getMonth();

				props.data.forEach(function (element: Item) {
					if (element.year == currentYear) {
						element.valueByMonths = element.valueByMonths.slice(0, currentMonth + 1);
					}

					chartData.data.datasets.push({
						label: `${element.userLogin} (${element.year} г.)`,
						data: element.valueByMonths
					});
				});

				const element = await waitForElement('#' + props.chartId) as HTMLCanvasElement;
				chart = new Chart(element, chartData);
			}

			(async () => {
				await createPlot();
			})();
		}
	}
